import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from "@/store";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import ActivityIndicator from 'vue-activity-indicator'
import  VueCookie from 'vue-cookie';

/*
import { PdfViewerPlugin } from '@syncfusion/ej2-vue-pdfviewer';
import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhWH9cc3RUTmlUVkM=');
*/

Vue.use(ActivityIndicator, VueCookie)

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
import './assets/css/style.css';


import './registerServiceWorker'
import i18n from './i18n'
import ICS from 'vue-ics'
Vue.use(ICS, {});

Vue.config.productionTip = false
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
