<template>
  <div class="s3m-reset-password">
    <v-card  v-if="showPassword" width="400px" class="mt-5 mx-auto">
      <v-card-title>{{$t('New Password')}}</v-card-title>
      <v-card-text>
        Das hier neu vergebene Passwort gilt für alle Seiten und die AuFBau-App des Netzweks des Fachverband der Stuckateure für Ausbau und Fassade Baden-Württemberg. Die alten Passwörter sind damit ungültig.
        <v-form value>
          <v-text-field
              :label="$t('password')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password"
              tabindex="3"
          />
          <div class="s3m_password_requirements">{{$t("password_requirements")}}</div>
          <v-text-field
              :label="$t('password repeat')"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="passwordConfirmation"
              tabindex="4"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="saveForm"
            tabindex="5"
        >
          {{$t('Save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <my-snack-bar ref="mySnackbar"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import shared from "@/services/shared";
import MySnackBar from "@/components/MySnackBar";
import APIServiceWP from "@/services/APIServiceWP";
import VueCookie from "vue-cookie";

export default {
  name: "ResetPassword",
  components: {MySnackBar},
  data() {
    return {
      token: "",
      password: '',
      passwordConfirmation: '',
      showLogin: false,
      showPassword: true
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    this.showPassword = true;
  },
  methods:{
    saveForm() {
      if (this.password.length < 8 || !shared.validatePassword(this.password)) {
        this.$refs.mySnackbar.error(this.$t("password_malformed"))
        return;
      }
      if ((this.password !== this.passwordConfirmation)) {
        this.$refs.mySnackbar.error( this.$t("passwords_dont_match"))
        return;
      }
      APIService.resetPassword(this.token, this.password)
          .then(async () => {
            this.$refs.mySnackbar.success(this.$t("The password has been reset successfully"))
            this.showPassword = false;
            let email = VueCookie.get('pwresetmail');
            if(email == null || !email || email === ""){
              email = window.prompt(this.$t("Leider konnte der Nutzer nicht identifiziert werden. Geben Sie bitte Ihre E-Mail-Adresse ein:"));
              console.log(email);
              if(email == null || !email || email === "") {
                this.$refs.mySnackbar.error(this.$t("Leider konnte die E-Mail-Adresse nicht identifiziert werden. Bitte starten sie den Prozess erneut und verwenden wenn möglich denselben Browser. Danke!"))
                setTimeout(() => { document.location.href = "https://veranstaltungen.ausbau-akademie.de/app/forgot-password"; }, 0);
                return;
              }
            }
            console.log(email+" reset ");
            await this.syncSites(email, this.password);
            VueCookie.set('pwresetmail', '');
            setTimeout(() => { document.location.href = "https://shop.ausbau-akademie.de/?sso=1"; }, 0);

          })
          .catch(() => {
            this.$refs.mySnackbar.error(this.$t("Password reset failed"));
          });

    },
    async syncSites(email, password){
      const user = {};

      user.email = email;
      user.password = password;

      console.log(user);

      let sites = JSON.parse(process.env.VUE_APP_SYNC_SITES);
      for(let i=0; i < sites.length; i++) {
        console.log("sync " + sites[i]);
        APIServiceWP.setAPIClient(sites[i]);
        let ret = await APIServiceWP.setUserPassword(user);
        console.log(ret);
      }
      return true;
    },
  }
}
</script>

<style scoped>

</style>
