import axios from 'axios'
let serverURL_Shop = process.env.VUE_APP_API_SHOP
let serverURL_Komzet = process.env.VUE_APP_API_KOMZET;
let serverURL_SAF = process.env.VUE_APP_API_SAF;

const config_shop = {
    baseURL: serverURL_Shop + '/wp-json/s3m/v1',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 100000
}


// eslint-disable-next-line no-unused-vars
const apiClientShop = axios.create(config_shop)
apiClientShop.interceptors.request.use((config_shop) => {
    const token = process.env.VUE_APP_API_TOKEN_SHOP
    if (token) {
        config_shop.headers.Authorization = `Bearer ${token}`
    }
    return config_shop
})

const config_komzet  = {
    baseURL: serverURL_Komzet + '/wp-json/s3m/v1',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 100000
}
const apiClientKomzet = axios.create(config_komzet);
apiClientKomzet.interceptors.request.use((config_komzet) => {
    const token = process.env.VUE_APP_API_TOKEN_KOMZET
    if (token) {
        config_komzet.headers.Authorization = `Bearer ${token}`
    }
    return config_komzet
})

const config_saf  = {
    baseURL: serverURL_SAF + '/wp-json/s3m/v1',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    timeout: 100000
}
const apiClientSAF = axios.create(config_saf);
apiClientSAF.interceptors.request.use((config_saf) => {
    const token = process.env.VUE_APP_API_TOKEN_SAF
    if (token) {
        config_saf.headers.Authorization = `Bearer ${token}`
    }
    return config_saf
})

let apiClient = apiClientSAF;

export default {
    /*login (username, password) {
        return apiClient.post('/token', {
            username,
            password
        }, {
            baseURL: serverURL + '/wp-json/jwt-auth/v1'
        })
    },*/
    setAPIClient(clientname){
        switch (clientname){
            case "shop":{
                apiClient = apiClientShop;
                break;
            }
            case "komzet":{
                apiClient = apiClientKomzet;
                break;
            }
            case "saf":{
                apiClient = apiClientSAF;
                break;
            }
        }
    },
    logout () {
        // Invalidate token
    },
    getAllSG () {
        return apiClient.get('/sg').then(result => {
            //console.log(result.data)
            return result.data
        })
    },
    getSG(id){
        return apiClient.get('/sg/'+id).then(result => {
            //console.log(result.data)
            return result.data[0];
        })
    },
    deleteSG (id) {
        return apiClient.delete('/sg/'+id).then(result => {
            //console.log(result.data)
            return result.data
        })
    },
    saveSG(model, id = 0){
        if(parseInt(id) > 0){
            let modeljs = JSON.stringify(model);
            return apiClient.put('/sg/'+id, modeljs).then(result =>{
                return result
            })
        }else{
            return apiClient.post('/sg/', model).then(result =>{
                return result
            })
        }
    },
    getUsers () {
        return apiClient.get('/users')
    },
    postUser (user) {
        console.log("post user");
        console.log(user);
        return apiClient.post('/sync/users', { ...user })
    },
    setUserPassword(user){
        return apiClient.post('/sync/user/password', { ...user })
    },
    setUserRole (user) {
        return apiClient.post('/sync/user/role', { ...user })
    },
    putUser (user) {
        const id = user.id
        delete user.id
        return apiClient.put(`/sync/users/${id}`, user)
    },
    deleteUser (user) {
        return apiClient.delete(`/sync/users/${user.id}`)
    },
    getGroups (parentId) {
        return apiClient.get(`/groups${parentId ? '?parent_id=' + parentId : ''}`).then(result => {
            console.log(result);
            return result.data;
        })
    },
    getRootGroups () {
        return this.getGroups(0)
    },

    putGroup (group, id) {
        console.log("put group "+id);
        console.log(group);
        return apiClient.put(`/sync/groups/${id}`, group)
    },
    deleteGroup (group) {
        console.log(`/sync/groups/${group.id}`);
        return apiClient.delete(`/sync/groups/${group.id}`).then(result => {
            console.log(result);
            return result;
        })
    },
    postGroup (group) {
        console.log('Create Group')
        return apiClient.post('/sync/groups', group)
    }
}
