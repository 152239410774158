<template>
  <v-app>

    <v-snackbar
        v-model="loginFail"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{$t('sso_login_failed')}}: {{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>
<script>

import VueCookie from "vue-cookie";
import APIService from "@/services/APIService";
import shared from "@/services/shared";
import EventBus from "@/services/EventBus";
import {mapMutations} from "vuex";

export default {
  name: 'SSO Redirect',
  data () {
    return {
      loginFail: false,
      loginFailMessage: "Login fehlgeschlagen!",
      email: "",
      tokens: null
    }
  },
  methods: {
    ...mapMutations(['updateUser']),
    async doLogin() {

      let stateToken = VueCookie.get('ssoStateToken');
      let targetRoute = VueCookie.get('targetLoginRoute');
      if (this.$route.query.state !== stateToken) {
        this.loginFailMessage = "state Token";
        this.loginFail = true;
      } else {
        let data = await APIService.getAuthToken(this.$route.query.code, process.env.VUE_APP_HOME_URL+"/anmeldung-sso/");
        let parts = data.id_token.split(".");
        let user = shared.decodeToken(parts[1]);
        console.log(user);
        this.tokens = {refresh:{token: data.refresh_token}, access:{token: data.access_token}};
        let token_user = {user: user, tokens:this.tokens};
        this.email = user.email;
        this.updateUser(token_user);
        //get user data
        await this.getUserData(user.sub);
        APIService.sendEvent('LoginEvent');
        if(targetRoute !== 'undefined' && targetRoute != ""){
          await this.$router.push(targetRoute);
        }else{
          await this.$router.push("/app/courses");
        }

      }
    },
    async getUserData(userId){
      await APIService.getUser(userId).then((data) => {
        let token_user = {user: data, tokens:this.tokens};
        console.log(data);
        this.updateUser(token_user)
        this.$router.push({name: 'courses', params:{ instance: 'lms' }} )
        EventBus.$emit('updateUserEvent');
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.logout()
        this.loginFailMessage = this.$t("login_fail_message")
        this.loginFail = true
        this.password = ''
        Promise.reject()
      });
    }
  },
  created() {
    //this.getUserData('61a6372243c4a9002fba54f4');
    this.doLogin();

  },
  beforeRouteUpdate(to, from, next) {
    this.doLogin();
    next();
  }
}
</script>

<style scoped>

</style>
