import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import APIService from "@/services/APIService";

Vue.use(Vuex)

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
  },
  state: {
    user: null,
    users: [],
  },
  getters: {
    isLoggedIn: (state) => {
      return state.user !== null
    },
    accessToken: (state) => {
      return state?.user?.tokens?.access?.token
    },
    refreshToken: (state) => {
      return state?.user?.tokens?.refresh?.token
    },
    showAdmin: (state) => {
      console.log("test");
      console.log(state.user);
      if(typeof state?.user?.admin !== "undefined" && typeof state.user.admin.showAdmin !== "undefined" && state.user.admin.showAdmin){
        return true;
      }
      return false
    }
  },
  mutations: {
    updateUser(state, data) {
      const {user, tokens} = data
      state.user = user
      state.user.tokens = tokens


      console.log('update user');
      console.log(user);

      let admin = {};
      if(typeof state.user.admin !== "undefined"){
        admin = state.user.admin;
      }else{
        admin.showAdmin = false;
      }

      if(typeof user.hasTeamLicences !== "undefined"){
        if(user.hasTeamLicences){
          admin.showAdmin = true;
        }
      }

      if(typeof user.isCourseManager !== "undefined"){
        if(user.isCourseManager){
          admin.showAdmin = true;
        }
      }

      if(user.role == "admin"){
        admin.showAdmin = true;
      }
      state.user.admin = admin;
    },
    updateUserData(state, data){
      state.user = { ...state.user, data}
    },
    updateTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    logout(state) {
      state.user = null
    },
    updateUsers(state,users) {
      this.state.users = users
    },
  },
  actions: {
    logout({commit, getters}){
      if(getters.isLoggedIn) {
        APIService.logout(getters.refreshToken).then()
        commit('logout')
      }
    },
    login({commit}, {email, password}){
      return APIService.login(email, password).then((user) => {
        commit('updateUser', user)
      })
    },
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateTokens', tokens)
        return tokens
      })
    },
    fetchUsers({commit}) {
      return APIService.getCourseUsers().then((users) => {
        commit('updateUsers', users)
      })
    },
  }
})

export default store
