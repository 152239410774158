import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import SSOLogin from "@/views/SSOLogin";
import SSORedirect from "@/views/SSORedirect";
import Error from "@/views/Error";
import AdminLayout from "@/layouts/AdminLayout";
import NotFound from "@/views/NotFound";
import ResetPassword from "@/views/ResetPassword";
import ForgotPassword from "@/views/ForgotPassword";
import SSOLogout from "@/views/SSOLogout.vue";
import OtpLogin from "@/views/OtpLogin.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: AdminLayout,
    //component: () => import(/* webpackChunkName: "chapterList" */ '../views/Home')
  },
  {
    path: '/sso',
    name: 'ssoLogin',
    component: SSOLogin
  },
  {
    path: '/logout-sso',
    name: 'ssoLogout',
    component: SSOLogout,
  },
  {
    path: '/anmeldung-sso',
    name: 'ssoRedirect',
    component: SSORedirect,

  },
  {
    path: '/otp-login',
    name: 'otpLogin',
    component: OtpLogin
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/app',
    component: AdminLayout,
    children: [
        {
      path: 'reset-password',
      name: 'resetPassword',
      component: ResetPassword
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword
      },
      {
        path: 'login',
        name: 'userlogin',
        component: Login
      },
      {
        path: 'verify-email',
        name: 'verifyemail',
        component: Login
      },
      {
        path: 'signup',
        name: 'userSignup',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/Signup')
      },
      {
        path: 'profile',
        name: 'userProfile',
        component: () => import('../views/Profile')
      },
      {
        path: 'change-password',
        name: 'changePassword',
        component: () => import('../views/ChangePassword')
      },
      {
        path: 'reporting',
        name: 'reporting',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/Reporting')
      },
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "chapterList" */ '../views/Home')
      },
      {
        path: ':userId/courses/',
        name: 'userCourses',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseList')
      },
      {
        path: 'courses',
        name: 'courses',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseList')
      },
      {
        path: 'demo',
        name: 'coursesDemo',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseDemoList')
      },
      {
        path: 'allCourses',
        name: 'coursesAll',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/CourseAllList')
      },
      {
        path: 'course/:course',
        name: 'course',
        component: () => import(/* webpackChunkName: "courseList" */ '../views/Course')
      },
      {
        path: 'downloads',
        name: 'downloads',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/DownloadList')
      }
    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
