<template>
  <div>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>{{$t('login')}}</h1>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
            :label="$t('username')"
            prepend-icon="mdi-account-circle"
            v-model="email"
          />
          <v-text-field
            :label="$t('password')"
            prepend-icon="mdi-lock"
            :type="showLogin ? 'text' : 'password'"
            :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showLogin=!showLogin"
            v-model="password"
            @keydown.enter.prevent="login"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          class="mx-auto px-4"
          @click="login"
        >
          {{$t('login')}}
        </v-btn>
        <v-btn
            color="primary"
            class="mx-auto px-4"
            @click="forgotPassword"
        >
          {{$t('forgot password')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="loginFail"
      color="error"
      multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
        v-model="emailVerified"
        color="success"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{$t('emailSuccessMessage')}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-snackbar
        v-model="emailError"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{$t('emailErrorMessage')}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import APIService from "@/services/APIService";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import EventBus from "@/services/EventBus";
import shared from "@/services/shared";
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      showLogin: false,
      loginFail: false,
      emailVerified: false,
      emailError: false,
      loginFailMessage: '',
      token: ""
    }
  },
  async mounted() {
    if(this.$route.query.token && this.$route.name === "verifyemail") {
      this.token = this.$route.query.token;
      let token_parts = this.token.split(".");
      let userData = shared.decodeToken(token_parts[0]);
      console.log(userData);
      let success = await APIService.verifyEmail(this.token);
      console.log(success);
      if(success){
        this.emailVerified = true;
      }else{
        this.emailError = true;
      }
    }
    console.log(this.$route.query);
    if(this.$route.query.doLogin && this.$route.query.doLogin === "1"){
      console.log("user: "+this.$route.query.user);
      console.log("pass: "+this.$route.query.pass);
      console.log("start="+this.$route.query.start);
      console.log("startA="+this.$route.query.startA);

      let user = shared.decodeString(this.$route.query.user, parseInt(this.$route.query.start, 10));
      console.log("decoded user: "+user);

      let pass = shared.decodeString(this.$route.query.pass, parseInt(this.$route.query.startA, 10));


      console.log("decoded pass: "+pass);

      if (!user || !pass) {
        await this.$router.push({name: 'demo'});
      } else {
          this.email = user;
          this.password = pass;
          this.login();
      }
    }
  },
  methods: {
    ...mapMutations(['updateUser']),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['logout']),
    login () {
      APIService.login(this.email, this.password).then((data) => {
        console.log(data);
        this.updateUser(data)
        this.$router.push({name: 'courses', params:{ instance: 'lms' }} )
        EventBus.$emit('updateUserEvent');
      }).catch(error => {
        console.log("Login failed", error)
        this.logout()
        this.loginFailMessage = this.$t("login_fail_message")
        this.loginFail = true
        this.password = ''
        Promise.reject()
      })

    },
    forgotPassword() {
      APIService.forgotPassword("stark@s3-medien.de");
    }
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  }
}
</script>

<style scoped>

</style>
