<template>
  <v-app>

    <v-snackbar
        v-model="loginFail"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{$t('sso_login_failed')}}: {{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-app>
</template>

<script>
import APIService from "@/services/APIService";
import EventBus from "@/services/EventBus";
import {mapMutations} from "vuex";

export default {
  name: "OtpLogin",
  data () {
    return {
      loginFail: false,
      loginFailMessage: "Login fehlgeschlagen!",
      email: "",
      tokens: null
    }
  },
  methods: {
    ...mapMutations(['updateUser']),
    async doLogin() {
      if(!this.$route.query.token || this.$route.query.token == ""){
        //redirect to login page, login and save partnerPassword
        this.$router.push("/app/home")
        return;
      }
      APIService.otpLogin(this.$route.query.token).then((data) => {

        this.updateUser(data);

        console.log("login success");
        console.log(data);

        if(data.user.firstName == null || data.user.lastName == null || data.user.firstName == "" || data.user.lastName == ""){
          this.$router.push({name: 'userProfile', params:{ type: 'forceName' }} )
          return;
        }
        EventBus.$emit('updateUserEvent');
        if(this.$route.query.course){
          this.$router.push("/app/course/"+this.$route.query.course+"?al=0");
        }else{
          //this.$router.push({name: 'courses', params:{ instance: 'lms' }} )
          this.$router.push("/app/courses/?al=0");
        }
      }).catch(error => {
        console.log(error);
        this.loginFailMessage = this.$t("login_fail_message")
        this.loginFail = true
        this.password = ''
       // Promise.reject();
        this.$router.push({name: 'coursesDemo', params:{ instance: 'lms' }} )
      })
    },
    async getUserData(userId) {
      return await APIService.getUser(userId).then((data) => {
        let token_user = {user: data, tokens: this.tokens};
        this.updateUser(token_user);
        EventBus.$emit('updateUserEvent');
        if (data.firstName == null || data.lastName == null || data.firstName == "" || data.lastName == "") {
          this.$router.push({name: 'userProfile', params: {type: 'forceName'}});
          return true;
        } else {
          this.$router.push({name: 'courses', params: {instance: 'lms'}})
          return false;
        }
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.logout()
        this.loginFailMessage = this.$t("login_fail_message")
        this.loginFail = true
        this.password = ''
        Promise.reject()
      });
    }
  },
  created() {
    //this.$router.push("/app/course/"+this.$route.query.courseid+"?al=0");
    this.doLogin();

  },
  mounted() {

    },
  beforeRouteUpdate(to, from, next) {
    next();
  }
}
</script>

<style scoped>

</style>
