<template>
  <div>
    An error occurred
    {{$route.params.message}}
  </div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>
