<template>
  <v-app style="background-color: #008bc4" :class="mainClass">
    <!--
    <div v-if="showTopImage" class="bg_top" :style="{height:this.contHeaderHeight}">
    </div>
    -->


    <v-navigation-drawer
                         v-model="drawerMain"
                         clipped
                         app
                         v-if="showNav"
                         class="hidden-md-and-up main-navigation"
                         style="z-index: 100"
    >
      <template v-slot:prepend>
        <div class="hidden-md-and-up" style="width: 100%;height: 20px;"><v-icon  @click="toggleNav();" style="position:absolute; right: 10px;cursor: pointer;">mdi-close</v-icon></div>
        <v-list-item v-if="isLoggedIn" two-line>
          <v-list-item-content>
            <v-list-item-title class="">{{ user && user.email }}</v-list-item-title>
            <v-list-item-subtitle class="">{{ $t("logged_in") }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list>

        <!--
        <v-list-item @click="openLink('https://www.stuck-verband.de/', false, true)">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.start") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <v-list-item v-if="isLoggedIn" to="/app/courses">
          <v-list-item-icon>
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.lms") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--
        <v-list-item v-if="true" to="/app/demo">
          <v-list-item-icon>
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.demo") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

        <v-list-item v-if="isLoggedIn" @click="openLink('https://shop.ausbau-akademie.de/?sso=1', false, true)">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Shop") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--
        <v-divider  v-if="(showAdmin && isLoggedIn)" />
        <v-list-item v-if="(showAdmin && isLoggedIn)" @click="openLink('https://admin.stuck-komzet.de', false, false)">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.admin") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdminUser && isLoggedIn" v-on:click="$router.push({name: 'coursesAll'})">
          <v-list-item-icon>
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.allCourses") }} <v-icon color="red">mdi-account-hard-hat</v-icon></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <!--
        <v-divider/>
        <v-list-item v-if="isLoggedIn && false"  to="/app/profile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("Profile") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <v-list-item v-if="isLoggedIn" @click="doLogout">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.logout") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--
        <v-list-item v-if="!isLoggedIn" to="/app/login">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("login") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <v-list-item class="s3m_heder_login" v-if="!isLoggedIn" to="/sso">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("login") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--
        <v-list-item v-if="!isLoggedIn" to="/app/signup">
          <v-list-item-icon>
            <v-icon>mdi-account-key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("signup") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
      </v-list>
      <!--
      <v-divider></v-divider>
      <div class="d-flex align-center locale-switcher-sub" style="">
        <LocaleSwitcher/>
      </div>-->
    </v-navigation-drawer>
    <div class="s3m-header">
      <img class="s3m_header_img" src="https://admin.stuck-komzet.de/ext/img/Ausbau_Akademie_Claim_rechts_s.jpg" alt="">

    <v-app-bar
        clipped-left
        app
        color="white"
        dark
        dense
        height="65"
        class="s3m_app_bar"
    >
      <div class="d-flex align-center header_login" style="background-color: green; height:calc(100% - 0px); padding: 20px; margin:3px; left: 0px;">
        <img style="cursor: pointer" @click="openLink('https:///www.stuck-komzet.de/?sso=1', false, true)" src="" height="30"/>

      </div>
      <v-spacer></v-spacer>
      <div class="hidden-md-and-down" id="topnav">
        <span v-if="isLoggedIn" v-on:click="$router.push({name: 'courses'})">{{ $t("nav.lms") }}</span>
        <span v-if="isLoggedIn" v-on:click="openLink('https://shop.ausbau-akademie.de/?sso=1', true, false)">{{ $t("Shop") }}</span>
      </div>
      <div class="hidden-md-and-up">
        <v-btn
            color="white"
            style="z-index: 4"
            class="main-burger"
            v-on:click="toggleNav()"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="hidden-md-and-down ">

        <!--
        <div v-if="isLoggedIn" v-on:click="$router.push({name: 'userProfile'})" class="d-flex align-center" style="cursor:pointer;background-color: transparent;border-radius: 5px;border: 2px solid #15406D; height: calc(34px); margin: 5px; padding: 10px;left: 0px;">
            <span style="color:black; font-weight: bold;margin-right: 5px;">{{ user && (user.lastName || user.loginName )}}</span>
            <v-icon style="color: #E20018 !important;">
              mdi-account-outline
            </v-icon>
        </div>
        -->
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                v-if="isLoggedIn"
                class="d-flex align-center s3m-profile-button"
                style="cursor:pointer;background-color: transparent;border-radius: 5px;border: 2px solid #15406D; height: calc(34px); margin: 5px; padding: 10px;left: 0px;">
              <span style="color:black; font-weight: bold;margin-right: 5px;">{{ user && (user.lastName || user.loginName )}}</span>
              <v-icon style="color: #E20018 !important;">
                mdi-account-outline
              </v-icon>
            </div>
          </template>

          <v-list class="hidden-md-and-down">
            <v-list-item @click="openLink('https://admin.stuck-komzet.de/admin/', true, true);">
              <v-list-item-title>Profil bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openPWChange()">
              <v-list-item-title>Passwort ändern</v-list-item-title>
            </v-list-item>
            <v-list-item @click="doLogout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div v-if="!isLoggedIn" v-on:click="$router.push({name: 'ssoLogin'})" class="d-flex align-center hidden-md-and-down s3m_header_login" style="cursor:pointer;background-color: transparent;border-radius: 5px;border: 2px solid #15406D; height: calc(34px); margin: 5px; padding: 10px;left: 0px;">
          <span style="color:black; font-weight: bold;margin-right: 5px;">{{ $t("login") }}</span>
          <v-icon style="color: #E20018 !important;">
            mdi-account-outline
          </v-icon>
        </div>
      </div>
    </v-app-bar>
    </div>

    <v-main>
      <v-container class="main-content">
        <router-view @test123="onHideMenuEvent"></router-view>
      </v-container>
    </v-main>
    <v-footer class="main-footer"><a target="_blank" href="https://www.stuck-verband.de/impressum/">{{ $t("nav.imprint") }}</a> <a target="_blank" href="https://www.stuck-komzet.de/datenschutzerklaerunng/">{{ $t("nav.privacy") }}</a></v-footer>
  </v-app>

</template>
<script>
import {mapGetters, mapState} from "vuex";
//import APIService from "@/services/APIService";
import EventBus from "@/services/EventBus";
import shared from "@/services/shared";
import VueCookie from "vue-cookie";
//import LocaleSwitcher from "@/components/LocaleSwitcher"
import APIService from "@/services/APIService";

let timeoutInMilliseconds = 1800000;

export default {
  name: "AdminLayout",
  components: {
  },
  data () {
    return {
      instances: null,
      progressActive: false,
      isAdminUser: false,
      showNav: true,
      drawerMain: false,
      showBurger: true,
      showTopImage: false,
      contHeaderHeight: "650px",
      inactivityTimer: null,
      mainClass: ""
    }
  },
  methods: {
    openPWChange(){
      VueCookie.set('referrer', 'https://veranstaltungen.ausbau-akademie.de');
      this.openLink('https://veranstaltungen.ausbau-akademie.de/app/change-password');
    },
    sizeChanged(){
      let w = window.innerWidth;
      let h = window.innerHeight;
      EventBus.$emit('windowSizeChanged', w, h);
    },
    toggleNav(){
      this.drawerMain = !this.drawerMain;
    },
    doLogout() {

      this.$store.dispatch('logout').then(() =>{
        shared.goToLink(process.env.VUE_APP_ADMIN_URL + '/sso/logout?redirect_uri=https://veranstaltungen.ausbau-akademie.de', false);
      })
    },
    openLink(url, popup = false, addLocale = false) {
      let locale = "";
      if(addLocale){
        locale = this.$i18n.locale;
      }
      shared.goToLink(url, popup, locale);
    },
    openRoute(routeName){
      this.$router.push({name:routeName}, false);
    },
    onHideMenuEvent(){
      console.log("hide");
    },
    updateUser(){
      //console.log("is admin? "+shared.userIsAdmin(this.user.role));
      this.username = this.getUserName();
      if(this.user !== null && typeof this.user !== 'undefined' && shared.userIsAdmin(this.user.role)){
        this.isAdminUser = true;
      }else{
        this.isAdminUser = false;
      }
    },
    getUserName(){
      return shared.getUserName(this.user);
    },
    //timer
    doInactive: function(){
      this.inactivityTimer = null;
      if(this.user){
        APIService.sendEvent("userActive", "0");
      }
      EventBus.$emit('userInactive', true);
    },
    resetTimer:  function () {
      window.clearTimeout(this.inactivityTimer)
      this.startTimer();
    },
    startTimer: function() {
      if(this.inactivityTimer == null){
        this.userActive();
      }
      this.inactivityTimer = window.setTimeout(this.doInactive, timeoutInMilliseconds)
    },
    setupTimers: function () {
      document.addEventListener("mousemove", this.resetTimer, false);
      document.addEventListener("mousedown", this.resetTimer, false);
      document.addEventListener("keypress", this.resetTimer, false);
      document.addEventListener("touchmove", this.resetTimer, false);
      this.startTimer();
    },
    userActive: function(){
      if(this.user) {
        APIService.sendEvent("userActive", "1");
      }
      EventBus.$emit('userInactive', false);
    },
    userLeaves: function(){
      console.log("user leaves");
      EventBus.$emit('userLeaves', true);
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'showAdmin']),
    ...mapState(['user']),
  },
  created(){
    this.updateUser();
    window.addEventListener("resize", this.sizeChanged);
    window.addEventListener('beforeunload', this.userLeaves)
    EventBus.$on('showTopImage', (show, height) => {
      this.showTopImage = show;
      this.contHeaderHeight = height+"px";
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeChanged);
  },
  mounted() {

    document.title = "Branchenzentrum AF online";

    this.setupTimers();

    this.mainClass = this.$route.name;

    if(this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
    }

    EventBus.$on('hideMenuEvent', (hide) => {
      if(hide == 'hide'){
        this.drawerMain = false;
        this.showBurger = false;
      }else{
        //this.drawerMain = true;
        this.showBurger = true;
      }
    });
    EventBus.$on('updateUserEvent', () => {
      this.updateUser();
    });

    if(this.$route.name == "Home") {
      if (this.isLoggedIn) {
        this.$router.push({name: 'courses', params: {}})
      } else {
        this.$router.push({name: 'coursesDemo', params: {}})
      }
    }
  }
}
</script>

<style scoped>

</style>
