<template>
  <div>
  </div>
</template>
<script>
import shared from "@/services/shared";
import {mapState} from "vuex";

export default {
  name: "SSOLogout",
  data () {
    return {
      redirectUri: "https://shop.ausbau-akademie.de"
    }
  },
  async mounted() {
    if(this.$route.query.lo && this.$route.query.lo === "1"){
      if(this.$route.query.redirect_uri && this.$route.query.redirect_uri !== ""){
        this.redirectUri = this.$route.query.redirect_uri;
      }
      this.doLogout();
    }
  },
  methods: {
    doLogout() {
      let email = this.user && this.user.email ? this.user.email : (this.$route.query.email ?  this.$route.query.email : "");
      console.log("email for logout:"+email);
      this.$store.dispatch('logout').then(() =>{
        console.log("logged out locally now admin ");
        shared.goToLink(process.env.VUE_APP_ADMIN_URL + '/sso/logout?email='+email+'&redirect_uri='+encodeURIComponent(this.redirectUri), false);
      })
    },
  },
  computed: {
    ...mapState(['user']),
  }
}
</script>

<style>

</style>
